import React from "react"
import { RouteComponentProps } from "react-router"
import { t } from "ttag"

import { WorkspaceCreationForm, WorkspaceFormValues } from "../WorkspaceCreationForm"
import { ContentWithSpeakupLogo } from "features/Shared/ContentWithSpeakupLogo"
import { useWindowSize } from "@humanpredictiveintelligence/myqvt-library"
import { Supported_Language_Code as SupportedLanguageCode } from "models/generated/index"
import * as Styles from "./WorkspaceCreationPage.styles"

import {
  useCreateWorkspaceMutation,
} from "graphql/mutations/generated/CreateWorkspace"

export const WorkspaceCreationPage = (props: RouteComponentProps) => {
  const windowSize = useWindowSize()

  const [ createWorkspace ] = useCreateWorkspaceMutation()

  return (
    <ContentWithSpeakupLogo>
      <Styles.CardContainer>
        <WorkspaceCreationForm
          onSubmit={submitForm}
          isTooltipDisabled={windowSize.isPortrait || windowSize.isReducedLandscape}
        />
      </Styles.CardContainer>
    </ContentWithSpeakupLogo>
  )

  function submitForm(data: WorkspaceFormValues) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await createWorkspace({
          context: {
            isUserVisitor: true,
          },
          variables: {
            customerName: data.enterprise,
            defaultLanguage: "fr_FR" as SupportedLanguageCode,
            defaultTimezone: "Europe/Paris",
            freemiumEmail: data.email,
            freemiumFirstName: data.firstName,
            freemiumLastName: data.lastName,
            freemiumPassword: data.password,
          },
        })

        resolve()
      }
      catch (error) {
        reject(error.graphQLErrors?.[0]?.message || t`An error has been encountered. Please try again later.`)
      }
    })
  }
}
