import styled from "styled-components/macro"

export const Container = styled.div`
  height: 100%;
  text-align: center;
`

export const Logo = styled.img`
  width: 70%;
  margin-bottom: 50px;
  
  ${({ theme }) => theme.mixins.reducedLandscapeAndPortrait} {
    width: 75%;
    margin-bottom: 24px;
  }
`

