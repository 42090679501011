/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestListWorkspacesQueryVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type RequestListWorkspacesQuery = { request_list_workspaces?: boolean | undefined };


export const RequestListWorkspacesDocument = gql`
    query RequestListWorkspaces($email: String!) {
  request_list_workspaces(email: $email)
}
    `;

/**
 * __useRequestListWorkspacesQuery__
 *
 * To run a query within a React component, call `useRequestListWorkspacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestListWorkspacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestListWorkspacesQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestListWorkspacesQuery(baseOptions: Apollo.QueryHookOptions<RequestListWorkspacesQuery, RequestListWorkspacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RequestListWorkspacesQuery, RequestListWorkspacesQueryVariables>(RequestListWorkspacesDocument, options);
      }
export function useRequestListWorkspacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequestListWorkspacesQuery, RequestListWorkspacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RequestListWorkspacesQuery, RequestListWorkspacesQueryVariables>(RequestListWorkspacesDocument, options);
        }
export type RequestListWorkspacesQueryHookResult = ReturnType<typeof useRequestListWorkspacesQuery>;
export type RequestListWorkspacesLazyQueryHookResult = ReturnType<typeof useRequestListWorkspacesLazyQuery>;
export type RequestListWorkspacesQueryResult = Apollo.QueryResult<RequestListWorkspacesQuery, RequestListWorkspacesQueryVariables>;