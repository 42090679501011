import * as React from "react"
import { Redirect, Route, RouteProps } from "react-router-dom"

import { APPLICATION_URL } from "./ApplicationNavigation"
import { useSession } from "features/Session"

export const HomeDisambiguationRoute: React.FunctionComponent<RouteProps> = (props) => {
  const session = useSession()

  return (
    <Route
      {...props}
      render={() => {
        if (!session.customer) {
          return <Redirect to={{ pathname: APPLICATION_URL.workspaceRedirection() }}/>
        }

        if (!session.authentication.isAuthenticated) {
          return <Redirect to={{ pathname: APPLICATION_URL.login() }}/>
        }

        return <Redirect to={{ pathname: APPLICATION_URL.speakupDashboard() }}/>
      }}
    />
  )
}
