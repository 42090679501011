import styled from "styled-components/macro"
import { PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"

export const Form = styled.form`
  width: ${({ theme }) => theme.sizes.homepageFormWidth};
  
  ${({ theme }) => theme.mixins.reducedLandscapeAndPortrait} {
    width: 100%;
  }
`

export const Fields = styled.div`
  display: grid;
  row-gap: 16px;
`

export const PasswordHint = styled.div`
  display: grid;
  row-gap: 8px;
`

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.danger};
  font-size: ${({ theme }) => theme.typography.fontSizeTextSmall};
`

export const SubmitButton = styled(PrimaryButton)`
  margin-top: 24px;
`

export const StatusContainer = styled.div`
  height: 114px; /** The size of the loader */
  display: flex;
  justify-content: center;
  align-items: center;
`
