import styled from "styled-components/macro"

import { PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"

export const Content = styled.div`
  display: grid;
  row-gap: 16px;
    
  ${props => props.theme.mixins.reducedLandscapeAndPortrait} {
    width: ${props => props.theme.sizes.homepageFormWidth};
    margin: auto;
  }
`

export const Form = styled.form`
  width: ${props => props.theme.sizes.homepageFormWidth};
    
  ${props => props.theme.mixins.reducedLandscapeAndPortrait} {
    width: 100%;
  }
`

export const Instructions = styled.div`
  font-size: ${props => props.theme.typography.fontSizeTextSmall};
`

export const SubmitAction = styled(PrimaryButton)`
  margin-top: 8px;
`

