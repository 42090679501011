import styled from "styled-components/macro"
import { Card } from "@humanpredictiveintelligence/myqvt-library"

export const CardContainer = styled(Card)`
  height: auto;
  padding: ${({ theme }) => theme.sizes.paddingHomepageCard};

  ${({ theme }) => theme.mixins.reducedLandscapeAndPortrait} {
    padding: 24px 24px;
  }
`
