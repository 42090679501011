export enum ApplicationPath {
  Base = "/",

  Workspace = "/workspace",
  CreateWorkspace = "/workspace/create",
  FindWorkspace = "/workspace/find",

  Login = "/login",
  Error = "/error",

  ChangePasswordRequest = "/user/password/requestchange",
  ChangePassword = "/user/password/change",

  SpeakUpScheduledSurveys = "/speakup/surveys/:surveyId(\\d+)?/:action(new|edit)?",
  SpeakUpDashboard = "/speakup/dashboard",

  LedDashboard = "/led/dashboard",
  LedDiscussions = "/led/discussions",
  LedStatistics = "/led/statistics",

  Users = "/users",
  Recipients = "/users/recipients",
  Permissions = "/users/administrators",
  Groups = "/users/groups",

  Settings = "/settings",
  WorkspaceRedirection = "/workspace",
}

export abstract class APPLICATION_URL {
  static base() {
    return "/"
  }

  static workspaceRedirection() {
    return ApplicationPath.Workspace
  }

  static createWorkspace() {
    return ApplicationPath.CreateWorkspace
  }

  static findWorkspace() {
    return ApplicationPath.FindWorkspace
  }

  static workspaceHome(workspaceName: string) {
    // eslint-disable-next-line max-len
    return `${window.location.protocol}//${workspaceName}.${process.env.REACT_APP_APPLICATION_HOSTNAME}${window.location.port && ":" + window.location.port}`
  }

  static login() {
    return ApplicationPath.Login
  }

  static error() {
    return ApplicationPath.Error
  }

  static changePasswordRequest() {
    return ApplicationPath.ChangePasswordRequest
  }

  static changePassword() {
    return ApplicationPath.ChangePassword
  }

  static speakupScheduledSurveys() {
    return "/speakup/surveys/"
  }

  static speakupDashboard() {
    return ApplicationPath.SpeakUpDashboard
  }

  static ledDashboard() {
    return ApplicationPath.LedDashboard
  }

  static scheduledSurvey(id: number, section?: string, commentId?: number | string) {
    let target = APPLICATION_URL.speakupScheduledSurveys() + id

    if (section) {
      target += `?section=${section}`
    }

    if (commentId) {
      target += `&comment=${commentId}`
    }

    return target
  }

  static editScheduledSurvey(id: number) {
    return APPLICATION_URL.speakupScheduledSurveys() + `${id}/edit`
  }

  static newScheduledSurvey() {
    return APPLICATION_URL.speakupScheduledSurveys() + "new"
  }

  static recipients() {
    return ApplicationPath.Recipients
  }

  static permissions() {
    return ApplicationPath.Permissions
  }

  static groups() {
    return ApplicationPath.Groups
  }

  static settings() {
    return ApplicationPath.Settings
  }
}
