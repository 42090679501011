import React from "react"

import { Menu } from "./Menu"
import { CenteredLayout, LeftOffsetLayout, TabsLayout, VerticalMenuLayout } from "@humanpredictiveintelligence/myqvt-library"
import { FeatureWithTabs, useNavigationTabs } from "./navigationTabs"

import backgroundIllustration from "../../assets/images/background_illustration.png"

export function withMenuLayout<T>(WrappedComponent: React.ComponentType<T>) {
  const menuComponent = (
    <Menu
      menuBackgroundGradientTopColor={"#034acf"}
      menuBackgroundGradientBottomColor={"#236fff"}
    />
  )

  return (props: T) => (
    <VerticalMenuLayout
      menuChildren={menuComponent}
    >
      <WrappedComponent {...props} />
    </VerticalMenuLayout>
  )
}

export function withTabsLayout<T>(WrappedComponent: React.ComponentType<T>, feature: FeatureWithTabs) {
  const menuComponent = (
    <Menu
      menuBackgroundGradientTopColor={"#034acf"}
      menuBackgroundGradientBottomColor={"#236fff"}
    />
  )

  return (props: T) => {
    const tabs = useNavigationTabs(feature)

    return (
      <VerticalMenuLayout
        menuChildren={menuComponent}
      >
        <TabsLayout tabs={tabs}>
          <WrappedComponent {...props} />
        </TabsLayout>
      </VerticalMenuLayout>
    )
  }
}

export function withCenteredLayout<T>(WrappedComponent: React.ComponentType<T>, options?: WithCenteredLayoutProps) {
  return (props: T) => (
    <CenteredLayout {...options}>
      <WrappedComponent {...props} />
    </CenteredLayout>
  )
}

export function withLeftOffsetLayout<T>(
  WrappedComponent: React.ComponentType<T>,
  options?: WithLeftOffsetLayoutProps,
) {
  return (props: T) => (
    <LeftOffsetLayout backgroundUrl={backgroundIllustration} {...options}>
      <WrappedComponent {...props} />
    </LeftOffsetLayout>
  )
}

export interface WithCenteredLayoutProps {
  backgroundColor?: string,
  backgroundUrl?: string,
  header?: React.ReactChild,
  left?: React.ReactChild,
  right?: React.ReactChild,
  footer?: React.ReactChild,
}

export interface WithLeftOffsetLayoutProps {
  backgroundColor?: string,
  backgroundUrl?: string,
}
