import * as React from "react"
import useForm from "react-hook-form"
import { Link, useHistory, useLocation } from "react-router-dom"
import { c, t } from "ttag"

import { LoginPageRouteState } from "features/Login/LoginPage"
import { AuthenticationContext } from "features/Authentication/AuthenticationContext"
import { APPLICATION_URL } from "features/Navigation"
import { useSession } from "features/Session"
import { CenteredLoader } from "@humanpredictiveintelligence/myqvt-library"
import { Input } from "@humanpredictiveintelligence/myqvt-library"
import { Title } from "@humanpredictiveintelligence/myqvt-library"
import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"
import { FormStatus } from "utilities/types"
import business from "config/business"
import * as Styles from "./LoginForm.styles"
import { STATIC_SESSION } from "utilities/StaticSession"

export const LoginForm: React.FC<LoginFormProps> = (props) => {
  const session = useSession()
  const authenticationApi = React.useContext(AuthenticationContext)
  const location = useLocation<LoginPageRouteState>()
  const history = useHistory()

  const [ formStatus, setFormStatus ] = React.useState<FormStatus>("idle")
  const {
    register: registerField,
    handleSubmit,
    errors: formErrors,
    formState: { isValid: isFormValid, dirty: isFormDirty },
    reset: resetForm,
    triggerValidation: validateForm,
  } = useForm<LoginFormFields>({ defaultValues: { email: props.email, password: props.password }, mode: "onChange" })

  return (
    <Styles.Form aria-label="form" onSubmit={handleSubmit(submitForm)} className={props.className}>
      <Title level="big">{c("Login form title").t`Sign in`}</Title>
      <Styles.Fields>
        <Input
          name="email"
          innerRef={registerField({
            pattern: {
              message: t`The email format is invalid`,
              value: business.patterns.email,
            },
            required: t`This field is required`,
          })}
          label={t`Email`}
          isDisabled={formStatus === "processing"}
          placeholder={t`Enter your email address`}
          isErroneous={!!formErrors.email}
          hint={formErrors.email && formErrors.email.message}
          isHintErroneous
        />

        <Input
          isPassword
          name="password"
          innerRef={registerField({
            required: t`This field is required`,
          })}
          label={t`Password`}
          isDisabled={formStatus === "processing"}
          placeholder={t`Enter your password`}
          isErroneous={!!formErrors.password}
          isHintErroneous={!!formErrors.password}
          hint={(
            <Styles.PasswordHint>
              {formErrors.password && formErrors.password.message}
              <Link to={APPLICATION_URL.changePasswordRequest()}>{t`Forgotten password?`}</Link>
            </Styles.PasswordHint>
          )}
        />
      </Styles.Fields>

      <Styles.StatusContainer>
        {formStatus === "failed" && !isFormDirty && (
          <Styles.ErrorMessage>
            {session.authentication.reason ?? t`Your credentials are invalid.`}
          </Styles.ErrorMessage>
        )}

        {formStatus === "processing" && <CenteredLoader isTransparent />}
      </Styles.StatusContainer>

      <Styles.SubmitButton
        submit
        disabled={!isFormValid}
      >
        {t`Sign in`}
      </Styles.SubmitButton>
    </Styles.Form>
  )

  /**
   * Called when the form is submitted
   * @param values Login form fields values
   */
  async function submitForm(values: LoginFormFields) {
    setFormStatus("processing")

    try {
      await authenticationApi.authenticate(values.email, values.password, STATIC_SESSION.customer)
      history.push(location.state?.redirectUrlAfterLogin || APPLICATION_URL.base())
    }
    catch {
      const { password, ...valuesToKeep } = values

      setFormStatus("failed")
      resetForm(valuesToKeep)
      validateForm(
        (Object.keys(valuesToKeep) as (keyof LoginFormFields)[])
          .map(fieldName => ({ name: fieldName })),
      )
    }
  }
}

export type LoginFormFields = {
  email: string,
  password: string,
}

export interface LoginFormProps extends BaseProps {
  /** Whether the form is loading */
  isLoading?: boolean,

  /** Autofill the email field with the email */
  email?: string,

  /** Autofill the password field with the password */
  password?: string,
}
