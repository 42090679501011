import * as React from "react"
import { useHistory } from "react-router-dom"
import { t } from "ttag"

import { LoginForm } from "../LoginForm"
import { ApplicationPath } from "features/Navigation"
import { ContentWithSpeakupLogo } from "features/Shared/ContentWithSpeakupLogo"
import { SecondaryButton } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./LoginPage.styles"

export const LoginPage: React.FC = () => {
  const history = useHistory()

  return (
    <ContentWithSpeakupLogo>
      <Styles.CardContainer>
        <LoginForm email={process.env.REACT_APP_DEV_LOGIN_USER} password={process.env.REACT_APP_DEV_LOGIN_PASSWORD} />
        {history.length > 1 && (
          <SecondaryButton onClick={redirectToWorkspaceRedirectionPage}>
            {t`Switch workspace`}
          </SecondaryButton>
        )}
      </Styles.CardContainer>
    </ContentWithSpeakupLogo>
  )

  /**
   * Go to WorkspaceRedirectionPage
   */
  function redirectToWorkspaceRedirectionPage() {
    history.push(ApplicationPath.WorkspaceRedirection)
  }
}

export interface LoginPageRouteState {
  /** Redirect route after login */
  redirectUrlAfterLogin: string,
}
