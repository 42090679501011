import React from "react"

import speakupLogo from "assets/images/speakup_logo_full_white.svg"
import * as Styles from "./ContentWithSpeakupLogo.styles"

export const ContentWithSpeakupLogo: React.FC = props => (
  <Styles.Container>
    <Styles.Logo src={speakupLogo} alt="SpeakUP Pulse Survey"/>
    {props.children}
  </Styles.Container>
)
