import styled, { css } from "styled-components/macro"
import { CenteredLoader } from "@humanpredictiveintelligence/myqvt-library"

export const Form = styled.form`
  width: ${({ theme }) => theme.sizes.homepageFormWidth};

  text-align: center;
  font-size: ${({ theme }) => theme.typography.fontSizeTextSmall};
  
  ${({ theme }) => theme.mixins.reducedLandscapeAndPortrait} {
    width: 100%;
  }
`

export const Content  = styled.div`
  display: grid;
  row-gap: 16px;

  margin-bottom: 24px;
`

export const Loader = styled(CenteredLoader)``

export const SuccessIcon = styled.img`
  height: 50px;
`

const Message = css`
  text-align: center;
`
export const ErrorMessage = styled.div`
  ${Message};
  color: ${({ theme }) => theme.colors.danger};
  margin-bottom: 16px;
`
export const SuccessMessage = styled.p`
  ${Message};
`
