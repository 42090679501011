import React from "react"
import { RouteComponentProps } from "react-router"
import { Link } from "react-router-dom"
import { t } from "ttag"

import { APPLICATION_URL } from "features/Navigation"
import { ContentWithSpeakupLogo } from "features/Shared/ContentWithSpeakupLogo"
import { PrimaryButton, Title } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./WorkspaceRedirectionPage.styles"

export const WorkspaceRedirectionPage = (props: RouteComponentProps) => {
  const [ workspaceName, setWorkspaceName ] = React.useState("")

  return (
    <ContentWithSpeakupLogo>
      <Styles.CardContainer>
        <Styles.Form onSubmit={submitWorkspaceName}>
          <Title level="big">{t`Sign in`}</Title>
          <Styles.InputField
            value={workspaceName}
            label={t`Workspace`}
            placeholder={t`Enter your workspace name`}
            onChange={setWorkspaceName}
            hint={(
              <Styles.RedirectionHints className="LoginForm__password-hint">
                <Link to={APPLICATION_URL.createWorkspace()}>{t`Create an account`}</Link>
                <Link to={APPLICATION_URL.findWorkspace()}>{t`Forgotten workspace ?`}</Link>
              </Styles.RedirectionHints>
            )}
          />
          <PrimaryButton disabled={workspaceName === ""} submit>
            {t`Continue`}
          </PrimaryButton>
        </Styles.Form>
      </Styles.CardContainer>
    </ContentWithSpeakupLogo>
  )

  function submitWorkspaceName(event: React.FormEvent) {
    event.preventDefault()
    window.location.href = APPLICATION_URL.workspaceHome(workspaceName)
  }
}
