import * as React from "react"
import { t } from "ttag"

import businessConfig from "config/business"
import { Input, InputProps, PrimaryButton, Title, useForm } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./WorkspaceCreationForm.styles"

import validationGif from "assets/images/validation.gif"

export const WorkspaceCreationForm: React.FC<WorkspaceCreationFormProps> = (props) => {
  const form = useForm<WorkspaceFormValues>({ mode: "onChange" })
  const [ serverErrorMessage, setServerErrorMessage ] = React.useState<string | undefined>()

  const commonFieldProps: Partial<InputProps> = {
    hintDisplay: "onfocus",
    isDisabled: form.status === "processing" || form.status === "submitted",
    tooltipDisplay: "onfocus",
  }
  const passwordSecurityRules = <div dangerouslySetInnerHTML={{
    __html: t`For an optimal security, your password must be composed of:<br/>
    - 8 or more characters<br/>
    - at least one lowercase letter<br/>
    - at least one uppercase letter<br/>
    - at least one number<br/>
    - at least one special character`,
  }} />

  return (
    <Styles.Form onSubmit={form.handleSubmit(submitForm)}>
      <Title level="big">
        {t`Create account`}
      </Title>

      <Styles.Content>
        <Input
          {...commonFieldProps}
          name="firstName"
          label={t`First name`}
          placeholder={t`Enter your first name`}
          innerRef={form.register({ required: t`This field is required` })}
          isErroneous={form.errors.firstName !== undefined}
          {...hintAndTooltipProps("firstName", t`Your first name will be visible to survey recipients`)}
        />
        <Input
          {...commonFieldProps}
          name="lastName"
          label={t`Last Name`}
          placeholder={t`Enter your last name`}
          innerRef={form.register({ required: t`This field is required` })}
          isErroneous={form.errors.lastName !== undefined}
          {...hintAndTooltipProps("lastName", t`Your last name will be visible to survey recipients`)}
        />
        <Input
          {...commonFieldProps}
          name="email"
          label={t`Email`}
          placeholder={t`Enter your email address`}
          innerRef={form.register({
            pattern: {
              message: t`The email format is invalid`,
              value: businessConfig.patterns.email,
            },
            required: t`This field is required`,
          })}
          isErroneous={form.errors.email !== undefined}
          {...hintAndTooltipProps("email")}
        />
        <Input
          {...commonFieldProps}
          name="enterprise"
          label={t`Enterprise`}
          placeholder={t`Enter your enterprise name`}
          innerRef={form.register({
            required: t`This field is required`,
          })}
          isErroneous={form.errors.enterprise !== undefined}
          {...hintAndTooltipProps("enterprise", t`The enterprise name will be visible to the recipients`)}
        />
        <Input
          {...commonFieldProps}
          isPassword
          name="password"
          label={t`Password`}
          placeholder={t`Enter your password`}
          innerRef={form.register({
            pattern: {
              message: t`The password doesn't follow the security rules`,
              value: businessConfig.patterns.password,
            },
            required: t`This field is required`,
          })}
          isErroneous={form.errors.password !== undefined}
          {...hintAndTooltipPropsForPassword()}
        />
        <Input
          {...commonFieldProps}
          isPassword
          name="passwordConfirmation"
          label={t`Password confirmation`}
          placeholder={t`Confirm your password`}
          innerRef={form.register({
            required: t`This field is required`,
            validate: value => {
              return value === form.getValues().password || t`The confirmation doesn't match the password`
            },
          })}
          isErroneous={form.errors.passwordConfirmation !== undefined}
          {...hintAndTooltipProps("passwordConfirmation")}
        />
      </Styles.Content>

      {form.status === "failed" && serverErrorMessage && (
        <Styles.ErrorMessage>
          {serverErrorMessage}
        </Styles.ErrorMessage>
      )}

      {(form.status === "idle" || form.status === "failed") && (
        <PrimaryButton
          submit
          disabled={!form.isValid}
        >
          {t`Create my account`}
        </PrimaryButton>
      )}

      {form.status === "processing" && (
        <Styles.Loader isTransparent />
      )}

      {form.status === "submitted" && (
        <>
          {!props.isTooltipDisabled &&
              <Styles.SuccessIcon src={validationGif} alt={t`Success`}/>
          }
          <Styles.SuccessMessage>
            {// eslint-disable-next-line max-len
              t`Your account is being created. You will receive an e-mail containing information to access the administration interface in a few minutes.`
            }
          </Styles.SuccessMessage>
        </>
      )}
    </Styles.Form>
  )

  /**+
   * Submit the form and handle the submission status
   */
  async function submitForm(data: WorkspaceFormValues) {
    form.setStatus("processing")
    setServerErrorMessage(undefined)

    try {
      await props.onSubmit(data)
      form.setStatus("submitted")
    }
    catch (errorMessage) {
      const { password, passwordConfirmation, ...fieldValuesToKeep } = data

      form.setStatus("failed")
      form.reset(fieldValuesToKeep)
      setServerErrorMessage(errorMessage)
      form.triggerValidation(
        // We need to narrow the type of the returned value from Object.keys
        // Because triggerFormValidation expects a specific type depending on WorkspaceFormValues properties
        (Object.keys(fieldValuesToKeep) as (keyof WorkspaceFormValues)[])
          .map(fieldName => ({ name: fieldName })),
      )
    }
  }

  /**
   * Get the props managing the tooltip and hint text
   * depending on whether there are errors in the form or we're on mobile
   * @param fieldName Name of the field to handle
   * @param text Text to display in the hint or tooltip
   */
  function hintAndTooltipProps(fieldName: keyof WorkspaceFormValues, text?: string) {
    const helperTextProps: Pick<InputProps, "hint" | "tooltipText" | "hintDisplay" | "isHintErroneous">
      = {}

    if (!props.isTooltipDisabled) {
      helperTextProps.tooltipText = text
    }

    if (form.errors[fieldName] !== undefined) {
      helperTextProps.hint = form.errors[fieldName]!.message
      helperTextProps.hintDisplay = "always"
      helperTextProps.isHintErroneous = true

      return helperTextProps
    }

    if (props.isTooltipDisabled) {
      helperTextProps.tooltipText = undefined
      helperTextProps.hint = text
    }

    return helperTextProps
  }

  /**
   * Get the props managing the tooltip and hint text specifically for the password field
   * depending on whether there are errors in the form or we're on mobile
   */
  function hintAndTooltipPropsForPassword() {
    const helperTextProps:
      Pick<InputProps, "hint" | "tooltipText" | "tooltipDisplay" | "hintDisplay" | "isHintErroneous"> = {}

    if (props.isTooltipDisabled) {
      helperTextProps.hint = passwordSecurityRules
      helperTextProps.isHintErroneous = form.errors.password !== undefined
      helperTextProps.tooltipText = undefined
      helperTextProps.hintDisplay = form.errors.password !== undefined ? "always" : "onfocus"
    }
    else {
      helperTextProps.tooltipText = passwordSecurityRules
      helperTextProps.hint = form.errors.password && form.errors.password.message
      helperTextProps.isHintErroneous = form.errors.password !== undefined
      helperTextProps.hintDisplay = form.errors.password !== undefined ? "always" : "onfocus"
      helperTextProps.tooltipDisplay = form.errors.password !== undefined ? "always" : "onfocus"
    }

    return helperTextProps
  }
}

export type WorkspaceFormValues = {
  firstName: string,
  lastName: string,
  email: string,
  enterprise: string,
  password: string,
  passwordConfirmation: string,
}

export interface WorkspaceCreationFormProps {
  /**
   * Function called when the form is valid and submitted
   */
  onSubmit: (data: WorkspaceFormValues) => Promise<void>,

  /**
   * If the component is displayed on mobile
   */
  isTooltipDisabled?: boolean,
}
