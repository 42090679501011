import React from "react"
import { Route, Switch } from "react-router-dom"

import { WelcomeTitle } from "@humanpredictiveintelligence/myqvt-library"
import { ChangePasswordPage } from "features/ChangePassword/ChangePasswordPage"
import { ChangePasswordRequestPage } from "features/ChangePassword/ChangePasswordRequestPage"
import { DashboardPage } from "features/Dashboard"
import { ErrorPage } from "features/Error/ErrorPage"
import { LedKeyFactsPage } from "features/Led/LedKeyFactsPage"
import { LedDiscussionsPage } from "features/Led/LedDiscussionsPage"
import { LedStatisticsPage } from "features/Led/LedStatisticsPage"
import { LoginPage } from "features/Login/LoginPage"
import {
  ApplicationPath,
  AuthenticatedRoute,
  FeatureWithTabs,
  HomeDisambiguationRoute,
  PageNotFoundRoute,
  WorkspacedRoute,
  withCenteredLayout,
  withLeftOffsetLayout,
  withMenuLayout,
  withTabsLayout,
} from "features/Navigation"
import { PermissionsPage } from "features/Permissions"
import { RecipientsPage } from "features/Recipients"
import { ScheduledSurveysPage } from "features/Surveys"
import Tutorial from "features/Tutorial/Tutorial"
import { UserGroupsPage } from "features/UserGroups"
import { UserSettingsPage } from "features/UserSettings/UserSettingsPage"
import { FindWorkspacePage } from "features/Workspace/FindWorkspacePage"
import { WorkspaceCreationPage } from "features/Workspace/WorkspaceCreationPage"
import { WorkspaceRedirectionPage } from "features/Workspace/WorkspaceRedirectionPage"

import { PackageCode, UserPermissionCode } from "models/generated"

const centeredLayoutHeader = <WelcomeTitle/>

const App = () => <>
  <Tutorial/>
  <Switch>
    {/*This route redirects to the correct home page depending on the workspaced and logged-in status*/}
    <HomeDisambiguationRoute path={ApplicationPath.Base} exact/>

    <Route
      path={ApplicationPath.CreateWorkspace}
      exact
      component={withLeftOffsetLayout(WorkspaceCreationPage)}
    />
    <Route
      path={ApplicationPath.WorkspaceRedirection}
      exact
      component={withLeftOffsetLayout(WorkspaceRedirectionPage)}
    />
    <Route
      path={ApplicationPath.FindWorkspace}
      exact
      component={withLeftOffsetLayout(FindWorkspacePage)}
    />

    <WorkspacedRoute
      path={ApplicationPath.Login}
      exact
      component={withLeftOffsetLayout(LoginPage)}
    />
    <WorkspacedRoute
      path={ApplicationPath.ChangePassword}
      exact
      component={withLeftOffsetLayout(ChangePasswordPage)}
    />
    <WorkspacedRoute
      path={ApplicationPath.ChangePasswordRequest}
      exact
      component={withLeftOffsetLayout(ChangePasswordRequestPage)}
    />

    <AuthenticatedRoute
      path={ApplicationPath.SpeakUpDashboard}
      exact
      component={withTabsLayout(DashboardPage, FeatureWithTabs.SpeakUp)}
    />
    <AuthenticatedRoute
      path={ApplicationPath.SpeakUpScheduledSurveys}
      exact
      component={withTabsLayout(ScheduledSurveysPage, FeatureWithTabs.SpeakUp)}
    />

    <AuthenticatedRoute
      path={ApplicationPath.Recipients}
      exact
      component={withTabsLayout(RecipientsPage, FeatureWithTabs.Users)}
      permissionsSet={[ UserPermissionCode.RecipientsRead ]}
    />
    <AuthenticatedRoute
      path={ApplicationPath.Permissions}
      exact
      component={withTabsLayout(PermissionsPage, FeatureWithTabs.Users)}
      permissionsSet={[ UserPermissionCode.PermissionsManage ]}
    />
    <AuthenticatedRoute
      path={ApplicationPath.Groups}
      exact
      component={withTabsLayout(UserGroupsPage, FeatureWithTabs.Users)}
      permissionsSet={[ UserPermissionCode.UserGroupsManage ]}
    />

    <AuthenticatedRoute
      path={ApplicationPath.LedDashboard}
      exact
      component={withMenuLayout(LedKeyFactsPage)}
      packageCode={PackageCode.Led}
    />

    <AuthenticatedRoute
      path={ApplicationPath.LedStatistics}
      exact
      component={withMenuLayout(LedStatisticsPage)}
      packageCode={PackageCode.Led}
    />

    <AuthenticatedRoute
      path={ApplicationPath.LedDiscussions}
      exact
      component={withMenuLayout(LedDiscussionsPage)}
      packageCode={PackageCode.Led}
    />

    <AuthenticatedRoute
      path={ApplicationPath.Settings}
      exact
      component={withMenuLayout(UserSettingsPage)}
    />

    <Route
      path={ApplicationPath.Error}
      exact
      component={withCenteredLayout(ErrorPage, { header: centeredLayoutHeader })}
    />
    <PageNotFoundRoute/>
  </Switch>
</>

export default App
