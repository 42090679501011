import React from "react"

import { CenteredLoader } from "@humanpredictiveintelligence/myqvt-library"
import { ContentWithSpeakupLogo } from "features/Shared/ContentWithSpeakupLogo"
import { withCenteredLayout } from "features/Navigation"

import * as Styles from "./SplashScreen.styles"

export const SplashScreen = withCenteredLayout(() => (
  <ContentWithSpeakupLogo>
    <Styles.Container>
      <CenteredLoader isTransparent size="m" color="light" />
    </Styles.Container>
  </ContentWithSpeakupLogo>
))
