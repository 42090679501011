import styled from "styled-components/macro"

export const Form = styled.form`
  width: ${({ theme }) => theme.sizes.homepageFormWidth};
  
  ${({ theme }) => theme.mixins.reducedLandscapeAndPortrait} {
    margin: auto;
  }
`

export const Hint = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSizeTextSmall};
`
