import styled from "styled-components/macro"

import { Card } from "@humanpredictiveintelligence/myqvt-library"

export const CardWrapper = styled(Card)`
  padding: 40px 120px;
  height: initial;
  text-align: center;
    
  ${props => props.theme.mixins.reducedLandscapeAndPortrait} {
    padding: 24px;
    animation: padding 0.25s;
  }
`

