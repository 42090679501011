import styled from "styled-components/macro"
import { Card } from "@humanpredictiveintelligence/myqvt-library"
import { Input } from "@humanpredictiveintelligence/myqvt-library"

export const CardContainer = styled(Card)`
  height: auto;
  box-sizing: border-box;

  padding: 40px 120px;
  
  ${({ theme }) => theme.mixins.reducedLandscapeAndPortrait} {
    padding: 24px;
  }
`

export const Form = styled.form`
  width: ${({ theme }) => theme.sizes.homepageFormWidth};

  text-align: center;

  ${({ theme }) => theme.mixins.reducedLandscapeAndPortrait} {
    width: 100%;
  }
`

export const InputField = styled(Input)`
  width: 100%;
  margin-bottom: 24px;
`

export const RedirectionHints = styled.div`
  display: flex;
  gap: 16px;
`

