import React from "react"
import { RouteComponentProps } from "react-router"
import { t } from "ttag"

import { FindWorkspaceForm, FindWorkspaceFormFields } from "../FindWorkspaceForm"
import { APPLICATION_URL } from "features/Navigation"
import { ContentWithSpeakupLogo } from "features/Shared/ContentWithSpeakupLogo"
import { SecondaryButton, useForm } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./FindWorkspacePage.styles"

import { useRequestListWorkspacesLazyQuery } from "graphql/queries/generated/RequestListWorkspaces"

export const FindWorkspacePage = (props: RouteComponentProps) => {
  const [ requestWorkspacesList ] = useRequestListWorkspacesLazyQuery({
    onCompleted() {
      form.setStatus("submitted")
    },
    onError() {
      form.setStatus("failed")
    },
  })

  const form = useForm({})

  return (
    <ContentWithSpeakupLogo>
      <Styles.CardContainer>
        <FindWorkspaceForm onSubmit={submitMail} onRedirect={redirectToLoginPage} formStatus={form.status}/>
        {form.status !== "submitted" && props.history.length > 1 && (
          <SecondaryButton onClick={goBack}>
            {t`Back`}
          </SecondaryButton>
        )}
      </Styles.CardContainer>
    </ContentWithSpeakupLogo>
  )

  /**
   * Submit the mail provided
   * @param variables the registered values of the form
   */
  async function submitMail(variables: FindWorkspaceFormFields) {
    form.setStatus("processing")
    await requestWorkspacesList({
      context: {
        isUserVisitor: true,
      },
      variables,
    })
  }

  /**
   * Go back in the navigation history
   */
  function goBack() {
    props.history.goBack()
  }

  /**
   * Redirects to Login page
   */
  function redirectToLoginPage() {
    props.history.push(APPLICATION_URL.workspaceRedirection())
  }
}
