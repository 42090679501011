/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateWorkspaceMutationVariables = Types.Exact<{
  customerName: Types.Scalars['String'];
  freemiumEmail: Types.Scalars['String'];
  freemiumPassword: Types.Scalars['String'];
  freemiumFirstName: Types.Scalars['String'];
  freemiumLastName: Types.Scalars['String'];
  defaultLanguage: Types.Scalars['String'];
  defaultTimezone: Types.Scalars['String'];
}>;


export type CreateWorkspaceMutation = { createWorkspace?: { id: number, code_name: string } | undefined };


export const CreateWorkspaceDocument = gql`
    mutation CreateWorkspace($customerName: String!, $freemiumEmail: String!, $freemiumPassword: String!, $freemiumFirstName: String!, $freemiumLastName: String!, $defaultLanguage: String!, $defaultTimezone: String!) {
  createWorkspace: addFreemiumCustomer(customerName: $customerName, freemiumEmail: $freemiumEmail, freemiumPassword: $freemiumPassword, freemiumFirstName: $freemiumFirstName, freemiumLastName: $freemiumLastName, defaultLanguage: $defaultLanguage, defaultTimezone: $defaultTimezone) {
    id
    code_name
  }
}
    `;

/**
 * __useCreateWorkspaceMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkspaceMutation, { data, loading, error }] = useCreateWorkspaceMutation({
 *   variables: {
 *      customerName: // value for 'customerName'
 *      freemiumEmail: // value for 'freemiumEmail'
 *      freemiumPassword: // value for 'freemiumPassword'
 *      freemiumFirstName: // value for 'freemiumFirstName'
 *      freemiumLastName: // value for 'freemiumLastName'
 *      defaultLanguage: // value for 'defaultLanguage'
 *      defaultTimezone: // value for 'defaultTimezone'
 *   },
 * });
 */
export function useCreateWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>(CreateWorkspaceDocument, options);
      }
export type CreateWorkspaceMutationHookResult = ReturnType<typeof useCreateWorkspaceMutation>;
export type CreateWorkspaceMutationResult = Apollo.MutationResult<CreateWorkspaceMutation>;
export type CreateWorkspaceMutationOptions = Apollo.BaseMutationOptions<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>;