import * as React from "react"
import { jt, t } from "ttag"

import business from "config/business"
import { CenteredLoader, Input, SecondaryButton, Title } from "@humanpredictiveintelligence/myqvt-library"
import useForm from "react-hook-form"

import * as Styles from "./ChangePasswordRequestForm.styles"

export const ChangePasswordRequestForm: React.FC<ChangePasswordRequestFormProp> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitted, isSubmitting, isValid },
    errors,
  } = useForm<ChangePasswordRequestFields>({ mode: "onChange" })

  const supportLink = <a href="mailto:devs@groupe-jlo.com" key="supportLink">devs@groupe-jlo.com</a>

  return (
    <>
      <Styles.Form onSubmit={handleSubmit(submitForm)}>
        <Title level="big">
          {t`Password reset`}
        </Title>
        {!isSubmitted && (
          <>
            <Styles.Content>
              <Input
                name="email"
                innerRef={register({
                  pattern: {
                    message: t`The email format is invalid`,
                    value: business.patterns.email,
                  },
                  required: true,
                })}
                label={t`email address`}
                isDisabled={isSubmitting}
                placeholder={t`Enter your email address`}
                hint={errors.email?.message}
                isErroneous={!!errors.email}
                isHintErroneous={!!errors.email}
              />
              <Styles.Instructions>
                {t`Please fill in your email address. If the address you provide is associated to an administrator
                account, an email will be sent to it.`}
              </Styles.Instructions>
            </Styles.Content>
            <CenteredLoader isTransparent isHidden={!isSubmitting} />
            <Styles.SubmitAction
              submit
              disabled={!isValid}
            >
              {t`Send`}
            </Styles.SubmitAction>
          </>
        )}

        {isSubmitted && (
          <>
            <Styles.Instructions>
              {t`If the provided address is associated to an administrator account, an email containing a password reset
              link has been sent.`}
            </Styles.Instructions>
            <Styles.Instructions>
              {jt`If you do not receive this email, please contact ${supportLink}.`}
            </Styles.Instructions>
            <Styles.SubmitAction
              onClick={props.onGoLogin && props.onGoLogin}
            >
              {t`Sign in`}
            </Styles.SubmitAction>
          </>
        )}
      </Styles.Form>
      {props.onGoBack && !isSubmitted &&
        <SecondaryButton
          onClick={props.onGoBack}
        >
          {t`Back`}
        </SecondaryButton>
      }
    </>
  )

  /**
   * Send the form content to the onSubmit method while managing the form submission state
   * @param values the values of the form
   */
  async function submitForm(values: ChangePasswordRequestFields) {
    if (isValid) {
      await props.onSubmit(values.email)
    }
  }
}

interface ChangePasswordRequestFormProp {
  /** The method called when the GoBack button is clicked */
  onGoBack?: () => void,

  /** The method called when the GoLogin button is clicked */
  onGoLogin?: () => void,

  /**
   * The method called when the form is submitted
   * @param email the email to send
   */
  onSubmit: (email: string) => void,
}

export type ChangePasswordRequestFields = {
  email: string,
}
