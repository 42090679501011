import styled from "styled-components/macro"
import { Card } from "@humanpredictiveintelligence/myqvt-library"

export const CardContainer = styled(Card)`
  padding: ${({ theme }) => theme.sizes.paddingHomepageCard};
  height: initial;
  text-align: center;
  
  ${({ theme }) => theme.mixins.reducedLandscapeAndPortrait} {
    padding: 24px;
    animation: padding 0.25s;
  }
`
