import styled from "styled-components/macro"

import { Card } from "@humanpredictiveintelligence/myqvt-library"
import { Title } from "@humanpredictiveintelligence/myqvt-library"
import { PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"

export const CardWrapper = styled(Card)`
  // 120 * 2 is x-padding. Use calc to avoid setting box-sizing to content-box
  width: calc(${props => props.theme.sizes.homepageFormWidth} + 120px * 2);
  padding: 40px 120px;
    
  ${props => props.theme.mixins.reducedLandscapeAndPortrait} {
    padding: 24px;
    animation: padding 0.25s;
  }
`

export const CardTitle = styled(Title)`
  text-align: center;
`

export const Action = styled(PrimaryButton)`
    margin-top: 24px;
`

export const Instructions = styled.p`
  font-size: ${props => props.theme.typography.fontSizeText};
`

