import styled from "styled-components/macro"

import { PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"

export const Action = styled(PrimaryButton)`
  margin: 16px 0;
`

export const Message = styled.div`
  margin: 16px 0;
  flex-grow: 1;
`

export const Title = styled.div`
  font-size: ${props => props.theme.typography.fontSizeTitleBig};
  font-weight: ${props => props.theme.typography.fontWeight};
  color: ${props => props.theme.colors.black};
  text-align: center;
  margin: 16px 0;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  
  height: 100%;
`

