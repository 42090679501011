import { usePackages } from "features/Packages"
import * as React from "react"
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom"

import { APPLICATION_URL } from "./ApplicationNavigation"
import { useSession } from "features/Session"
import { useTutorial } from "features/Tutorial"
import { useEffect } from "react"
import { firstLoginTutorial } from "features/Tutorial/contents/FirstLoginTutorial"
import { useUserPermissions } from "features/Permissions"
import { PackageCode, UserPermissionCode } from "models/generated"

export const AuthenticatedRoute: React.FC<AuthenticatedRouteProps>
  = ({ component: Component, location, packageCode, permissionsSet, ...rest }) => {
    const session = useSession()
    const { startTutorial } = useTutorial()
    const isUserAllowed = useUserPermissions()
    const { hasPackage } = usePackages()

    const isAllowedToStartTutorial =
      isUserAllowed(
        UserPermissionCode.RecipientsRead,
        UserPermissionCode.RecipientsManage,
        UserPermissionCode.ScheduledSurveyManage,
      )
      && !!session.customer?.freemiumCreatorEmail

    const hasPermissionsToAccess =
      (permissionsSet ? isUserAllowed(...permissionsSet) : true)
      && (packageCode ? hasPackage(packageCode) : true)

    useEffect(() => {
      if (isAllowedToStartTutorial) {
        startTutorial(firstLoginTutorial())
      }
      // eslint-disable-next-line
    }, [ isAllowedToStartTutorial ])

    return (
      <Route
        {...rest}
        render={(routeProps) => {
          if (!session.authentication.isAuthenticated) {
            return (
              <Redirect
                to={{
                  pathname: APPLICATION_URL.login(),
                  state: { redirectUrlAfterLogin: location && location.pathname + location.search },
                }}
              />
            )
          }
          if (!hasPermissionsToAccess) {
            return <Redirect to={APPLICATION_URL.base()}/>
          }
          return <Component {...routeProps}/>
        }}
      />
    )
  }

interface AuthenticatedRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps> | React.ComponentType,
  permissionsSet?: UserPermissionCode[],
  packageCode?: PackageCode,
}
