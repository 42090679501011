import React from "react"
import LineTo, { SteppedLineTo } from "react-lineto"

import { AdminPermission, PermissionsGroup } from "features/Permissions/models"
import { PermissionsToggle } from "features/Permissions/PermissionsToggle"
import { usePermissions } from "features/Permissions/PermissionsContext"
import { t } from "ttag"
import * as Styles from "./PermissionsGroupSection.styles"
import { permissionsLayout } from "config/permissions"

export const PermissionsGroupSection: React.FC<PermissionsGroupProps> = props => {
  const { actions, selectedAdmin, isAdminLoading } = usePermissions()
  const permissionsGroupLayout = permissionsLayout[props.permissionsGroup.code]

  // Rerender the component on window resize to update the tree lines position
  const [ , forceUpdate ] = React.useState<unknown>()
  React.useEffect(() => {
    window.addEventListener("resize", () => forceUpdate({}), { passive: true })

    return () => {
      window.removeEventListener("resize", () => forceUpdate({}))
    }
  }, [ forceUpdate ])
  
  return (
    <Styles.Container>
      <Styles.GroupName level="metricCard">{props.permissionsGroup.wording}</Styles.GroupName>
      <Styles.PermissionsTree className={props.permissionsGroup.code}>
        {props.permissionsGroup.permissions.map(renderPermission)}
      </Styles.PermissionsTree>
    </Styles.Container>
  )

  function renderPermission(userPermission: AdminPermission) {
    const permission = userPermission.permission
    const $gridPlacement =
      `${permissionsGroupLayout[permission.code][0]} /  ${permissionsGroupLayout[permission.code][1]}`

    const isScopeSensitiveWithScopesActive = !permission.isScopable && !!selectedAdmin?.scopes.length
    const isDisabled = (isAdminLoading && !userPermission.isGranted)
      || (userPermission.isDisabled || isScopeSensitiveWithScopesActive)

    return (
      <Styles.ToggleContainer
        $gridPlacement={$gridPlacement}
        className={`toggle-${permission.code}`}
        key={permission.code}
      >
        <PermissionsToggle
          onClick={() => !isAdminLoading && actions.togglePermission(permission.code)}
          isScopeSensitive={!permission.isScopable}
          tooltipText={isScopeSensitiveWithScopesActive
            ? t`You cannot select this option with a restricted scope.`
            : ""
          }
          isActive={userPermission.isGranted}
          isDisabled={isDisabled}
        >
          {permission.wording}
        </PermissionsToggle>
        {permission.parents?.length === 1 && (
          <LineTo
            delay={100}
            within={props.permissionsGroup.code}
            fromAnchor="right"
            toAnchor="0"
            from={`toggle-${permission.parents[0].code}`}
            to={`toggle-${permission.code}`}
            borderStyle="dashed"
            borderColor="grey"
            borderWidth={2}
          />
        )}
        {!!permission.parents?.length && permission.parents.length > 1 && permission.parents.map(parent => (
          <SteppedLineTo
            key={parent.code}
            delay
            within={props.permissionsGroup.code}
            fromAnchor="right"
            toAnchor="0"
            from={`toggle-${parent.code}`}
            to={`toggle-${permission.code}`}
            orientation="h"
            borderStyle="dashed"
            borderColor="grey"
            borderWidth={2}
          />
        ))}
      </Styles.ToggleContainer>
    )
  }
}

interface PermissionsGroupProps {
  permissionsGroup: PermissionsGroup,
}
