import styled from "styled-components/macro"

export const ErrorMessage = styled.p`
  margin: 40px 0;
  font-size: ${props => props.theme.typography.fontSizeTextSmall};
  color: ${props => props.theme.colors.danger};
`

export const Fields = styled.div`
  display: grid;
  row-gap: 16px;
  width: 100%;
`

export const Form = styled.form`
  height: 100%;
  ${props => props.theme.mixins.reducedLandscapeAndPortrait} {
    width: 100%;
  }
`

export const Loader = styled.div`
  margin: 40px 0;
`

