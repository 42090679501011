import React, { FormEvent } from "react"
import { jt, t } from "ttag"

import business from "config/business"
import { Input, PrimaryButton, Title, useForm } from "@humanpredictiveintelligence/myqvt-library"
import { FormStatus } from "utilities/types"
import * as Styles from "./FindWorkspaceForm.styles"

export const FindWorkspaceForm = (props: FindWorkspaceFormPropsTypes) => {
  const form = useForm<FindWorkspaceFormFields>({ mode: "onChange" })

  const supportLink = <a href="mailto:devs@groupe-jlo.com" key="supportLink">devs@groupe-jlo.com</a>

  return (
    <Styles.Form onSubmit={onSubmitHandle}>
      <Title level="big">{t`Find your workspace`}</Title>
      {props.formStatus !== "submitted" && (
        <>
          <Input
            name="email"
            innerRef={form.register({
              pattern: {
                message: t`The email format is invalid`,
                value: business.patterns.email,
              },
              required: true,
            })}
            label={t`Email`}
            isDisabled={props.formStatus === "processing"}
            placeholder={t`Enter your email address`}
            isErroneous={!!form.errors.email}
            isHintErroneous={!!form.errors.email}
            hint={form.errors.email?.message}
          />
          <Styles.Hint>
            {t`Please enter your email address. An email containing the details for your workspace will be sent
              if this address is associated to an account.`}
          </Styles.Hint>
          <PrimaryButton
            submit
            disabled={!form.isValid}
          >
            {t`Send`}
          </PrimaryButton>
        </>
      )}

      {props.formStatus === "submitted" && (
        <>
          <Styles.Hint>
            <p>
              {t`If the provided address is associated to an administrator account, an email containing a password reset
                link has been sent.`}
            </p>
            <p>
              {jt`If you do not receive this email, please contact ${supportLink}.`}
            </p>
          </Styles.Hint>
          <PrimaryButton
            onClick={props.onRedirect}
          >
            {t`Sign in`}
          </PrimaryButton>
        </>
      )}
    </Styles.Form>
  )

  function onSubmitHandle(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    props.onSubmit(form.getValues())
  }
}

export interface FindWorkspaceFormPropsTypes {
  /** Called when the form is submitted */
  onSubmit: (values: FindWorkspaceFormFields) => Promise<void>,

  /** Called when the redirect button is submitted */
  onRedirect: () => void,

  /** The status of the form */
  formStatus: FormStatus,
}

export type FindWorkspaceFormFields = {
  email: string,
}
